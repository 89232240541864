// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  overflow-x: hidden;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  &.overlay-open {
    overflow: hidden;

    .team-overlay {
      transform: scale(1);
      opacity: 1;
      z-index: 999;
      pointer-events: auto;

      .cards {
        transform: scale(1);
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}

strong {
  font-weight: 700;
}

img {
  display: block;
  max-width: 100%;
}

figure {
  display: block;
  margin: 0;
  padding: 0;
}

section {
  position: relative;
  z-index: 5;
}

.grid {
  display: grid;

  &.grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

//ul {
//    margin: 0;
//    padding: 0 0 0 50px;
//    list-style: disc outside none;
//
//    li {
//        margin-bottom: 30px;
//        padding-left: 50px;
//
//        &::marker {
//            color: $candyneon;
//        }
//
//        &:last-child {
//            margin: 0;
//        }
//    }
//}

.App {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
}

.main-bg {
  background-color: #020507;
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 966px;

  .main-gradient {
    height: 262px;
    width: 100%;
    align-self: flex-end;
    background: linear-gradient(180deg, rgba(2, 5, 7, 0) 0%, #020507 83.6%);
  }

  @media screen and (max-width: 978px) {
    height: 700px;
    background-size: 1200px;
  }

  @media screen and (max-width: 500px) {
    height: 600px;
    background-size: 800px;
  }
}

.main-layout {
  background-color: #020507;
  width: 100%;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;

  @media screen and (max-width: 500px) {
    background-size: 480px;
  }
}

.main-logo {
  height: 80px;

  @media screen and (max-width: 500px) {
    height: 60px;
  }
}

.default-layout {
  padding: 87px 150px;

  @media screen and (max-width: 978px) {
    padding: 60px;
  }

  @media screen and (max-width: 500px) {
    padding: 80px 30px;
  }
}

.default-text {
  font-family: 'Josefin Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
  line-height: 130%;
  color: #FFFFFF;

  @media screen and (max-width: 978px) {
    font-size: 46px;
  }

  @media screen and (max-width: 650px) {
    font-size: 40px;
  }

  @media screen and (max-width: 500px) {
    font-size: 30px;
  }
}

.heading-width {
  max-width: 950px;

  @media screen and (max-width: 500px) {
    max-width: 315px;
  }
}

.heading-padding {
  padding-top: 190px;

  @media screen and (max-width: 978px) {
    padding-top: 160px;
  }

  @media screen and (max-width: 650px) {
    padding-top: 130px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 110px;
  }
}

.project-heading-padding {
  padding-top: 450px;

  @media screen and (max-width: 978px) {
    padding-top: 300px;
  }

  @media screen and (max-width: 650px) {
    padding-top: 200px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 110px;
  }
}

.headline-text {
  font-family: 'Josefin Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;

  @media screen and (max-width: 978px) {
    font-size: 46px;
  }

  @media screen and (max-width: 650px) {
    font-size: 40px;
  }

  @media screen and (max-width: 500px) {
    font-size: 30px;
  }
}

.icons-row {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 170px;
  margin-top: 40px;

  @media screen and (max-width: 500px) {
    margin-bottom: 90px;
  }
}

.icon-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-width: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;

  &-voxo {
    &:hover {
      transform: scale(1.03);
      transition: all 0.3s ease-out;
      box-shadow: 0 0 33px 8px rgba(140,134,255, 0.3);
      background-color: rgba(140,134,255, 0.2);
    }
  }

  &-cdl {
    &:hover {
      transform: scale(1.03);
      transition: all 0.3s ease-out;
      box-shadow: 0 0 33px 8px rgba(104,219,255, 0.3);
      background-color: rgba(104,219,255, 0.2);
    }
  }
}

.landing-coming-soon {
  font-family: 'Josefin Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.2em;
  color: #FFFFFF;
}

.footer-box {
  position: fixed;
  bottom: 0;
  height: 50px;
  background-color: #020507;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 150px;

  &:before {
    content: "";
    position: absolute;
    left: 150px;
    bottom: 50px;
    height: 1px;
    width: calc(100% - 300px);
    border-top: 1px solid #CE843E;
  }

  @media screen and (max-width: 978px) {
    padding: 0 100px;

    &:before {
      content: "";
      position: absolute;
      left: 100px;
      bottom: 50px;
      height: 1px;
      width: calc(100% - 200px);
      border-top: 1px solid #CE843E;
    }
  }

  @media screen and (max-width: 650px) {
    padding: 0 50px;

    &:before {
      content: "";
      position: absolute;
      left: 50px;
      bottom: 50px;
      height: 1px;
      width: calc(100% - 100px);
      border-top: 1px solid #CE843E;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0 20px;
    position: fixed;
    height: 39px;

    &:before {
      content: "";
      position: absolute;
      left: 20px;
      bottom: 30px;
      height: 1px;
      width: calc(100% - 40px);
      border-top: 1px solid #CE843E;
    }
  }
}

.projects-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;

  @media screen and (max-width: 500px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.projects-box-before {
  padding-top: 110px;

  @media screen and (max-width: 500px) {
    padding-top: 60px;
  }
}

.projects-logo-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

.project-text {
  display: flex;
  align-content: flex-end;
  font-family: 'Josefin Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #FFFFFF;
}

.right-project {
  margin-left: 142px;

  @media screen and (max-width: 978px) {
    margin-left: 100px;
  }

  @media screen and (max-width: 650px) {
    margin-left: 50px;
  }

  @media screen and (max-width: 500px) {
    margin-left: unset;
  }
}

.footer-text {
  font-family: 'Josefin Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 50px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #EEBC7B;
  padding: 0;
  margin: 0;
  transition: transform 0.5s ease-out, filter 0.5s ease-out;

  &-link {
    &:hover {
      color: #FF665C;
      font-weight: 700;
      //transform: scale(1.1);
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.voxo-logo-m {
  margin: 40px auto 5px;
  width: 161px;

  @media screen and (max-width: 500px) {
    margin: 40px auto 5px;
    width: 100px;
  }
}

.cdl-logo-m {
  margin: 40px auto 5px;
  width: 187px;

  @media screen and (max-width: 500px) {
    margin: 40px auto 5px;
    width: 103px;
  }
}

.cdl-text-m {
  margin: 30px auto 0;

  @media screen and (max-width: 500px) {
    margin: 30px auto 0;
  }
}

.image-container {
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;

  img {
    object-fit: cover;
    object-position: center top;
    transform-origin: center;
    transition: transform 0.3s ease-out;

    &:hover {
      transition: transform 0.2s ease-out, filter 0.2s ease-out;
      transform: scale(1.03);
    }
  }

  &-img-voxo {
    width: 580px;

    &:hover {
      transition: all 0.3s ease-out;
      box-shadow: 0 0 33px 8px rgba(140,134,255, 0.3);
    }

    @media screen and (max-width: 1356px) {
      width: 500px;
    }

    @media screen and (max-width: 1191px) {
      width: 450px;
    }

    @media screen and (max-width: 1191px) {
      width: 400px;
    }

    @media screen and (max-width: 978px) {
      width: 350px;
    }

    @media screen and (max-width: 832px) {
      width: 330px;
    }

    @media screen and (max-width: 803px) {
      width: unset;
    }

    @media screen and (max-width: 500px) {
      width: 315px;
    }
  }

  &-img-cdl {
    width: 580px;

    &:hover {
      transition: all 0.3s ease-out;
      box-shadow: 0 0 33px 8px rgba(238,188,123, 0.3);
    }

    @media screen and (max-width: 1356px) {
      width: 500px;
    }

    @media screen and (max-width: 1191px) {
      width: 450px;
    }

    @media screen and (max-width: 1191px) {
      width: 400px;
    }

    @media screen and (max-width: 978px) {
      width: 350px;
    }

    @media screen and (max-width: 832px) {
      width: 330px;
    }

    @media screen and (max-width: 803px) {
      width: unset;
    }

    @media screen and (max-width: 500px) {
      width: 315px;
    }
  }
}

.scroll-down5{
  width: 2px;
  height: 150px;
  position: relative;
  background: transparent;
  animation: scrollDown5 1.5s ease infinite;
  border-radius: 100%;
  margin: 80px auto 0 auto;

  @media screen and (max-width: 978px) {
    margin: 200px auto 0 auto;
  }

  @media screen and (max-width: 650px) {
    margin: 120px auto 0 auto;
  }

  @media screen and (max-width: 500px) {
    margin: 150px auto 0 auto;
    height: 100px;
  }

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, #EEBC7B, transparent);
  }
}

@keyframes scrollDown5{
  0%{
    transform-origin: top;
    transform: scaleY(0);
  }
  45%{
    transform-origin: top;
    transform: scaleY(1);
  }
  55%{
    transform-origin: bottom;
    transform: scaleY(1);
  }
  100%{
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

.twitter-text {
  font-family: 'Josefin Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  text-align: right;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-left: 10px;

  @media screen and (max-width: 581px) {
    display: none;
  }
}